import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout"
import loadable from "@loadable/component"

// Lazy load the ProductDesign component
const ProductDesign = loadable(() => import("../components/ProductDesign"))

export default function ServicesPage() {
  return (
    <Layout>
      <Helmet>
        <link rel="canonical" href="https://codefulcrum.com/product-design/" />
      </Helmet>

      <ProductDesign />
    </Layout>
  )
}
